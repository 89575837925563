@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounceIn {
  0% {
    transform: scale(0.8);
    opacity: 0;
  }
  60% {
    transform: scale(1.05);
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}

body {
  background-color: #f5f5f5;
}
a {
  text-decoration: none;
  pointer-events: auto; /* Ensure link is clickable */
  color: inherit;
}
@font-face {
  font-family: "ClashDisplay-Extralight";
  src: url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Extralight.woff2")
      format("woff2"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Extralight.woff")
      format("woff"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Extralight.ttf")
      format("truetype");
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Light";
  src: url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Light.woff2")
      format("woff2"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Light.woff")
      format("woff"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Light.ttf")
      format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Regular";
  src: url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Regular.woff2")
      format("woff2"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Regular.woff")
      format("woff"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Regular.ttf")
      format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Medium";
  src: url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Medium.woff2")
      format("woff2"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Medium.woff")
      format("woff"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Medium.ttf")
      format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Semibold";
  src: url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Semibold.woff2")
      format("woff2"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Semibold.woff")
      format("woff"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Semibold.ttf")
      format("truetype");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "ClashDisplay-Bold";
  src: url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Bold.woff2")
      format("woff2"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Bold.woff")
      format("woff"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Bold.ttf")
      format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
/**
* This is a variable font
* You can control variable axes as shown below:
* font-variation-settings: wght 700.0;
*
* available axes:
'wght' (range from 200.0 to 700.0
*/
@font-face {
  font-family: "ClashDisplay-Variable";
  src: url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Variable.woff2")
      format("woff2"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Variable.woff")
      format("woff"),
    url("./ClashDisplay_Complete/Fonts/WEB/fonts/ClashDisplay-Variable.ttf")
      format("truetype");
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}

/* -------------NAVBAR------------- */
.navbar {
  margin-left: auto;
  margin-right: auto;
  background-color: #f5f5f5;
}
.nav-item {
  font-family: "ClashDisplay-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 19.68px;
  letter-spacing: 1px;
  text-align: left;
}
.nav-link {
  position: relative;
  color: black;
}
.nav-link::after {
  content: "";
  transform: translateX(-50%);
  width: 0;
  height: 3px;
  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: #9a2c9a;
  transition: width 0.3s;
}
.nav-link:hover::after {
  width: 30%;
}
.missionwhite {
  color: #fff !important;
}
.missionlinewhite {
  border-bottom: 1.7px solid #fff;
}
.navba-toggler-icon{
  height: 60px;
  width: 47px !important;
}
.fa-bars{
  color: #9a2c9a !important;
}
.navenvelope {
  color: #414141;
  background-color: white;
  border-radius: 50%;
  padding: 7px;
  font-size: 14px;
}
.nav-link {
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
a {
  text-decoration: none;
}
a:hover {
  color: #bcbbbb;
}
.contactformflex {
  gap: 20px;
}
.mediabtn:hover {
  color: #bcbbbb;
  border: none !important;
  border-color: transparent;
  outline: none;
}
.mediabtn {
  font-family: "ClashDisplay-Regular";
  font-size: 18px !important;
  font-weight: 400;
  line-height: 19.68px;
  letter-spacing: 1px;
  text-align: left;
  padding-top: 18px;
}
.mediabtn:focus,
.mediabtn:active,
.mediabtn.active:hover,
.mediabtn:focus,
.mediabtn:active {
  outline: none;
  border: none;
  box-shadow: none;
}
.dropdown-item:hover {
  background-color: transparent;
  color: #c3c1c1;
}
.btn.show,
.btn-check:active + .btn,
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:active {
  border: none;
  outline: none;
  border-color: transparent;
}

@media only screen and (max-width: 1025px) {
  .navbar-toggler-icon {
    width: 46.65px;
    height: 60px;
  }
  .navbar-toggler {
    padding: 0 !important;
    border: none !important;
    outline: none !important;
  }
  .navbar-toggler:focus {
    border: none !important;
    outline: none !important;
    border-color: transparent;
  }
  .navbar-toggler:active {
    border: none !important;
    outline: none !important;
  }
  .navbar-toggler:focus,
  .navbar-toggler:active,
  .navbar-toggler-icon:focus {
    outline: none;
    box-shadow: none;
  }
}
/* ----------HOME PAGE----------- */
.homesection1 {
  padding-top: 150px;
  text-align: center;
}
.memojicontainer {
  display: inline-block;
  text-align: center;
  padding: 8px;
  border: 1px solid #c8c3c6;
  border-radius: 40px;
}
.femalememojipara {
  font-family: "ClashDisplay-Regular";
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
}

.homesection1heading {
  font-family: "ClashDisplay-Regular";
  font-size: 59px;
  font-weight: 600;
  line-height: 75.4px;
  text-align: center;
  background: linear-gradient(to right, #232323, #9a2c9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homesection1para {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: center;
  color: #414141;
}
.joinusbtn {
  font-family: "ClashDisplay-Regular";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
  color: #f6f6f6;
  background-color: #d213d2;
  padding: 10px 20px; /* Add some padding to make the button look better */
  border-radius: 5px;
}
.joinusbtn:hover {
  background-color: #9a2c9a;
}
.homesectiongroup {
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  gap: 10px;
}
.homesectionsubgroup {
  padding-top: 80px;
}
.donatebtn {
  font-family: "ClashDisplay-Regular";
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;
  background-color: #fff;
  color: #d213d2;
  padding: 10px 20px; /* Add some padding to make the button look better */
  border-radius: 5px;
  border-color: #d213d2;
}
.donatebtn:hover {
  background-color: #e0e0e0;
}
.homesection2 {
  gap: 250px;
  display: grid;
  grid-template-columns: auto auto;
}
.thick-dot {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #d213d2;
  border-radius: 50%;
}
.homesection2dotpara {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
  text-align: center;
}
.homesection2grid2para {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: left;
}
.bluehomesection2grid2para {
  color: #0d9595;
}
.homesection2subgrid {
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 20px;
}
.homesection2subgridgroup {
  padding: 15px;
  border-radius: 5px;
  border: 1px 0px 0px 0px;

  background-color: white;
}
.homesection2subgridheading {
  font-family: "ClashDisplay-Regular";
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
}
.homesection2subgridsubheading {
  font-family: "ClashDisplay-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.72px;
  text-align: left;
}
.homesection3grid {
  grid-template-columns: auto auto;
  gap: 30px;
  display: grid;
}
.homesection3heading {
  font-family: "ClashDisplay-Regular";
  font-size: 46px;
  font-weight: 600;
  line-height: 59.8px;
  text-align: center;
  background: linear-gradient(to right, #232323, #9a2c9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.homesection3subheading {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: center;
  color: #414141;
}
.homesection3img {
  width: 100%;
  border-radius: 10px;
}
.homesection4img {
  width: 100%;
}
.homesection4subgridsubheading {
  padding: 6px;
  background-color: #f5f5f5;
}
.homesection5 {
  text-align: left;
}
.homesection3grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 70px;
}
.homesection5grid {
  gap: 10px;
  display: grid;
  grid-template-columns: auto auto;
}
.homesection5img3 {
  height: 413.66px;
}
.homesection6grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 15px;
}
.testimonialname {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 600;
  line-height: 23.4px;
  text-align: left;
}
.testimoniallocation {
  font-family: "ClashDisplay-Regular";
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: #db42db;
}
.testimonialpara {
  font-family: "ClashDisplay-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.72px;
  text-align: left;
}
.homesection6grid {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 15px;
}
.testimonialgridbox {
}
.FAQs {
  background-color: #f6f6f6;
}
.FAQ {
}
.FAQNo {
  font-family: Urbanist;
  font-size: 52px;
  font-weight: 700;
  text-align: left;
}
.faqanswertitle {
  font-family: "ClashDisplay-Regular";
  color: #db42db;
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
}
.accordion-button {
  background-color: transparent !important;
  border: none !important;
  color: #d213d2 !important;
  text-decoration: none;
}

.accordion-body {
  font-family: "ClashDisplay-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 20.72px;
  text-align: left;
  color: #414141;
  padding: 0px 70px;
}
.accordion-item {
  border: none !important;
}
.accordion-item:focus {
  border: none !important;
}
.accordion-collapse {
  border: none !important;
}
.accordion-collapse:focus {
  border: none !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
/* --------------------FOOTER------------------- */
.footersection {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 50px;
  padding-top: 100px;
  padding-bottom: 20px;
}
.tellink {
  color: #414141db;
}
.contactfooterwhatsapp {
  font-size: 25px;
}

.social-media-icons {
  display: flex;
  gap: 13px;
  font-size: 15px;
}
.social-media-icon {
  padding: 7px 12px;
  color: white;
  background-color: #9a2c9a;
  border-radius: 50%;
}
.footerlogoo {
  height: 70px;
}
.logo{
  width: 200px;
}

.footerarrow {
  color: #d213d2;
}
.footersection2link {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  color: #414141db;
  text-align: left;
  padding-bottom: 15px;
}
.copyright {
  justify-content: space-between;
  font-family: Urbanist;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #414141;
}
.homeSegment8 {
  text-align: center;
  margin: auto;
  background-blend-mode: multiply; /* Experiment with other blend modes */
}
.homeSegment8title {
  font-family: Urbanist;
  font-size: 50px;
  font-weight: 600;
  line-height: 70px;
  text-align: center;
  color: rgba(231, 231, 231, 1);
  margin: auto;
  padding-top: 150px;
}
.homeSegment8subtitle {
  font-family: Urbanist;
  font-size: 18px;
  font-weight: 500;
  line-height: 25.2px;
  text-align: center;
  color: rgb(215, 214, 214);
}
.emailform {
  margin: auto;
}
.emailinput {
  padding: 20px 20px 20px 10px;
  border: none;
  width: 400px;
}
.email-container {
  background-color: white;
  margin: auto;
  border-radius: 100px;
  width: 600px;
  padding: 4px;
}
input::placeholder {
  font-family: Urbanist;
  font-size: 20px;
  font-weight: 400;
  line-height: 25.2px;
  text-align: left;
}
input[type="email"]:focus {
  outline: none;
  border: none;
}
.homeSegment8::after {
  content: ""; /* Empty content to create the element */
  position: absolute; /* Make it absolute for full coverage */
  bottom: 0; /* Position it at the bottom */
  left: 0;
  right: 0; /* Stretch it horizontally across the image */
  height: 50px; /* Adjust height for desired fade length */
  background-image: linear-gradient(
    to bottom,
    transparent 0%,
    rgba(0, 0, 0, 0.7) 100%
  ); /* Create the fade effect */
}

.footerbtnpara {
  font-size: 17px;
  font-weight: 400;
  line-height: 20.72px;
  font-family: "ClashDisplay-Regular";
  margin-right: 10px; /* Space between text and button */
}
.footerbtnflex {
  padding: 20px 15px; /* Adjust padding to suit your design */
  display: inline-flex; /* Makes the border wrap around the content */
  align-items: center;
  border: 1px solid #9a2c9a;
  border-radius: 10px;
}
.homeSegment8::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #f1f0f0cc; /* Semi-transparent overlay color */
  z-index: 1; /* Ensures the overlay is above the background image */
}
.homeSegment8 * {
  position: relative;
  z-index: 2; /* Ensure content is above the overlay */
}
.logodesc {
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: left;
  font-family: "ClashDisplay-Regular";
}
.footersection2title {
  font-family: "ClashDisplay-Regular";
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
}
.footersection2link {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: left;
}
.copyright {
}
.aboutsection1 {
  padding-top: 120px;
  text-align: center;
}
.aboutsection2subgrid {
  grid-template-columns: auto !important;
}
.homesection2subgridsubheading {
  background-color: #f5f5f5;
  padding: 10px;
}
.aboutsection2heading {
  font-family: "ClashDisplay-Regular";
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
}
.aboutsection2subheading {
  font-family: "ClashDisplay-Regular";
  font-size: 16px;
  font-weight: 400;
  line-height: 20.72px;
  text-align: left;
}
.aboutsection3 {
  display: grid;
  grid-template-columns: auto auto;
}
.aboutsection3group {
  display: flex;
  gap: 20px;
}
.wewadimg4{
  width: 280px;
}
.ceoname{
  font-family: "ClashDisplay-Regular";
  font-size: 21px;
  font-weight: 700;
  text-align: left;
}
.aboutsectiongrid4 {
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  gap: 20px;
  display: grid;
  grid-template-columns: auto auto;
}
.aboutsection4para {
  font-family: "ClashDisplay-Regular";
  font-size: 14px;
  font-weight: 400;
  line-height: 20.72px;
  text-align: left;
}
.gallerysection1 {
  padding-top: 100px;
}
.twocolumngrid {
  grid-template-columns: auto auto;
  display: grid;
}
.threecolumngrid {
  display: grid;
  row-gap: 20px;
  grid-template-columns: auto auto auto;
}
.fourcolumngrid {
  display: grid;
  grid-template-columns: auto auto auto auto;
}
.photo {
  border-radius: 10px;
}
.newphoto{
  border-radius: 10px;
  width:  100%;
  padding-bottom: 20px;
  height: auto;
}
.gallerygroup {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}
.gallerysubgroup {
  grid-template-columns: auto auto;
  display: grid;
  gap: 20px;
}
.gallerysubgroupbtn {
  padding-top: 120px;
}
.videogalleryflex {
  grid-template-columns: auto auto;
  display: grid;
  gap: 20px;
}
.videogallerypara1 {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 600;
  text-align: left;
}
.videogallerypara2 {
  font-family: "ClashDisplay-Regular";
  font-size: 16px;
  font-weight: 400;
  text-align: left;
}
.videogallerygrid {
  grid-template-columns: auto auto auto;
  display: grid;
  column-gap: 10px;
  row-gap: 20px;
}
.blogdate {
  font-family: "ClashDisplay-Regular";
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
}
.blog2columngrid {
  row-gap: 40px;
  column-gap: 20px;
}
.contactgrid {
  background-color: white;
  display: grid;
  grid-template-columns: auto auto;
}
.contactsubgrid {
  padding: 20px;
}
.contactsubgridheading {
  font-family: "ClashDisplay-Regular";
  font-size: 30px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
}
.contacttext {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: left;
}
.contacticoncontainer {
  padding: 7px;
  border-radius: 50px;
  border: 1px solid #c3c1c1;
  max-width: fit-content;
}
.contacticons {
  gap: 10px;
}
.contacticon {
  color: white;
  background-color: #9a2c9a;
  border-radius: 50%;
  padding: 10px;
}
.contactform {
  padding: 40px 30px;
  background-color: #f1e1f1;
  border-radius: 9px;
}
textarea {
  width: 100%;
  border-color: transparent;
  border-radius: 5px;
  height: 160px;
  padding: 10px;
}
.contactformbtn {
  text-align: center;
}
.formgroupsection input::placeholder {
  font-family: "ClashDisplay-Regular";
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
}
input:-internal-autofill-selected {
  background-color: transparent !important;
}
.formgroup {
  display: flex;
  gap: 30px;
  padding-bottom: 20px;
}
.formgroupsection label {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 600;
  line-height: 25.2px;
  text-align: left;
  display: block;
}
.formgroupsection input {
  height: 50px;
  border-color: transparent;
  border-radius: 5px;
  padding: 10px;
}
.maxwidth {
  width: 100%;
}
.blogpagepara {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  color: #414141;
}
.blogpageimg {
  width: 100%;
}
.twentypxgap {
  gap: 20px;
}
.blogpagesubheading {
  font-size: 40px;
  font-weight: 700;
  text-align: left;
  text-transform: uppercase;
}
.blogpagesubheadingsmall {
  font-size: 27px;
  font-weight: 500;
  text-align: left;
}
.donateheading {
  font-family: "ClashDisplay-Regular";
  font-size: 50px;
  font-weight: 600;
  line-height: 75.4px;
  text-align: center;
  background: linear-gradient(to right, #232323, #9a2c9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.donatepara {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: center;
}
.donatesubheading {
  font-family: "ClashDisplay-Regular";
  font-size: 30px;
  font-weight: 600;
  line-height: 39px;
  text-align: center;
}
.donategrid {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 1fr); /* Two equal-width columns */
}
.donatesubgrid {
  background-color: white;
  padding: 20px;
  display: inline-block;
}
.donatesubgridheading {
  font-family: "ClashDisplay-Regular";
  font-size: 24px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: left;
}
.donatesubgridpara {
  background-color: #f5f5f5;
  font-family: "ClashDisplay-Regular";
  font-size: 20px;
  font-weight: 400;
  line-height: 20.72px;
  text-align: left;
}
.donatefooterheading {
  font-family: "ClashDisplay-Regular";
  font-size: 26px;
  font-weight: 600;
  line-height: 31.2px;
  text-align: center;
  background: linear-gradient(to right, #232323, #9a2c9a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.donatefootersubheading {
  font-family: "ClashDisplay-Regular";
  font-size: 18px;
  font-weight: 400;
  line-height: 26.64px;
  text-align: center;
}
.teamimage{
  width: auto;
  height: 400px;
}
.teamimagebarbara{
  width: 400px;
}
.aboutsection4paralargefont{
  font-size: 19px;
  line-height: 30px;
}

@media only screen and (max-width: 1025px) {
  .section1image {
    width: 100%;
  }
  .newphoto{
    height: auto;
  }
  .homesection2 {
    grid-template-columns: auto;
    gap: 10px;
  }
  .homesection2subgrid {
    grid-template-columns: auto;
  }
  .homesection3grid {
    grid-template-columns: auto;
    gap: 20px;
  }
  .homesection6grid {
    grid-template-columns: auto;
  }
  .footersection {
    grid-template-columns: auto;
  }
  .footerbtnflex {
    display: block;
  }
  .homesection1heading {
    font-size: 30px;
    line-height: 39px;
    text-align: center;
  }
  .homesection1 {
    padding-top: 100px;
   
  }
  .homesectionsubgroup {
    padding-top: 30px;
  }
  .section1imagewrap {
    padding: 0 10px;
  }
  .homesection2 {
    text-align: left;
  }
  .homesection2dotpara {
    text-align: left;
  }
  .homesection2grid2para {
    font-size: 15px;
    font-weight: 400;
    line-height: 20.72px;
    text-align: left;
  }
  .homesection3heading {
    font-size: 35px;
    font-weight: 600;
    line-height: 39px;
    text-align: center;
  }
  .homesection3 {
    padding-top: 5px !important;
  }
  .homesection3subheading {
    padding-top: 15px;
    line-height: 21px;
    font-size: 17px;
  }
  .homesection2subgridheading {
    font-size: 21px;
  }
  .homesection5grid {
    grid-template-columns: auto auto;
  }
  .homesection5img3 {
    height: auto;
  }
  .faqanswertitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
    text-align: left;
  }
  .accordion-body {
    padding: 0 35px;
  }
  .FAQs {
    padding-top: 20px;
  }
  .footersection {
    gap: 20px;
    padding-top: 50px;
  }
  .footerbtnpara {
    padding-bottom: 10px;
  }
  .homesection3heading {
    padding-top: 40px;
  }
  .wewadimg4{
    width: 100%;
  }
  .mobilewidth {
    width: 100%;
  }
  .aboutsection3 {
    grid-template-columns: auto;
  }
  .aboutsectiongrid4 {
    grid-template-columns: auto;
  }
  .aboutsection1 {
    padding-top: 70px;
  }
  .aboutsection4para {
    padding-left: 10px;
    padding-right: 10px;
  }
  .footersection2link {
    font-size: 16px;
    font-weight: 400;
    line-height: 20.72px;
    text-align: left;
  }
  .blogpage1heading{

  }
  .blogpagesubheading {
    font-size: 25px;
  }
  .blogpagesubheadingsmall {
    font-size: 22px;
  }
  .blogpagepara{
    font-size: 16px;
  } 
  .photo {
    width: 100%;
  }
  .twocolumngrid {
    grid-template-columns: auto;
    gap: 10px;
  }
  .gallerysubgroup {
    grid-template-columns: auto;
    gap: 10px;
  }
  .gallerysubgroupbtn {
    padding-top: 0;
  }
  .threecolumngrid {
    grid-template-columns: auto;
    gap: 15px;
    text-align: center;
  }
  .fourcolumngrid {
    grid-template-columns: auto;
    text-align: center;
    gap: 15px;
  }
  .gallerysection1 {
    padding-top: 50px;
  }
  .videogalleryflex {
    grid-template-columns: auto;
    gap: 10px;
  }
  .videogallerygrid {
    grid-template-columns: auto;
  }
  iframe {
    width: 100% !important;
  }
  .homesectionimg {
    width: 100%;
  }
  .logodesc {
    font-size: 15px;
    line-height: 20.72px;
    text-align: left;
  }
  .footersection2title {
    font-size: 18px;
    line-height: 23.4px;
    text-align: left;
  }
  .homesection6 {
    padding-top: 70px;
  }
  .contactgrid {
    grid-template-columns: auto;
  }
  .formgroup {
    display: block;
  }
  .contactform {
    padding: 30px 20px;
  }
  .contactsubgrid {
    padding: 20px 0;
  }
  .contactsubgridheading {
    padding-left: 10px;
    font-size: 21px;
    line-height: 23.4px;
    text-align: left;
  }
  .formgroupsection label {
    padding-bottom: 5px;
    font-size: 16px;
  }
  .formgroupsection input {
    font-size: 14px;
  }
  .contactsubgridheading {
    font-size: 17px;
  }
  .homesection3subheading {
    font-size: 16px;
    line-height: 20.72px;
  }
  .donategrid {
    grid-template-columns: auto;
  }
  .donateheading {
    font-size: 24px;
    font-weight: 600;
    line-height: 31.2px;
    text-align: center;
  }
  .donatepara {
    font-size: 15px;
    font-weight: 400;
    line-height: 18.6px;
    text-align: center;
  }
  .donatesubheading {
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
    text-align: center;
  }
  .donatesection {
    padding-top: 70px !important;
  }
  .donatesubgridheading {
    font-size: 17px;
    font-weight: 600;
    line-height: 18.2px;
    text-align: left;
  }
  .donatesubgridpara {
    font-size: 15px;
    font-weight: 400;
    line-height: 15.6px;
    text-align: left;
  }
  .donatefooterheading{
    font-size: 20px;
  }
  .donatefootersubheading{
    font-size: 17px;
  }
  .footersection3heading{
    font-size: 32px;
  }
  .footerbtnpara{
    font-size: 15px;
    line-height: 19px;
  }
  .mediabtn{
    padding-left: 20px;
  }
  .fa-bars{
    font-size: 35px;
  }
  .logo{
    width: 150px;
  }
}
